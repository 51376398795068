import { StorageKeys } from "../utils/storage-keys";
import { OfflineEntity } from "./types";

class OfflineMode {
    // Все сохраенные оффлайн события
    entities: OfflineEntity[] = []

    // Инициалзиация
    init() {
        const raw = localStorage.getItem(StorageKeys.offlineData)
        if (raw) {
            this.entities = JSON.parse(raw)
        }
    }

    // Добавить новую запись
    save(entity: OfflineEntity) {
        this.entities.push(entity)
        localStorage.setItem(StorageKeys.offlineData, JSON.stringify(this.entities))
    }

    // Удвлить запись
    delete(entity: OfflineEntity) {
        const index = this.entities.indexOf(entity, 0);
        if (index > -1) {
            this.entities.splice(index, 1);
        }
        localStorage.setItem(StorageKeys.offlineData, JSON.stringify(this.entities))
    }
}

export default OfflineMode