import { useGetEquipmentPositionQuery, useGetScheduleDataQuery, useGetSlotDetailsQuery } from "../services"
import 'react-spring-bottom-sheet/dist/style.css'
import { Theme, presetGpnDefault } from '@consta/uikit/Theme';
import { EmptyDataPlaceholder } from "../../../components/EmptyDataPlaceholder"
import { ScheduleHeader } from "./schedule/components/ScheduleHeader"
import { SsmMap as SsmMap } from "../../../components/Map";
import { useDispatch, useSelector } from "react-redux";
import { updateEquipmentRoute, updateSelectedEquipmentPosition } from "../../../components/Map/slice";
import { Schedule } from "./schedule/components/Schedule";
import { Button } from "@consta/uikit/Button";
import { useEffect, useRef, useState } from "react";
import { IconHamburger } from '@consta/icons/IconHamburger';
import { ProfileSideBar } from "../../../components/ProfileSideBar";
import { ScheduleSkeleton } from "./schedule/components/ScheduleSkeleton";
import { ErrorDataPlaceholder } from "../../../components/ErrorDataPlaceholder";
import { useInterval } from "react-use";
import { EquipmentStateLabel } from "../../../components/EquipmentStateLabel";
import { updateCurrentEvent, updateCurrentRequest, updateCurrentSlot, updateEquipmentState, updateIssueRequest, updateRegNumber } from "../slice";
import { RootState } from "../../../store";
import { RegisterPlate } from "../../../components/RegisterPlate";
import { Space } from "../../../components/Space";
import { EquipmentStateAlertLabel } from "./schedule/components/EquipmentStateAlertLabel";
import Sheet, { SheetRef } from 'react-modal-sheet';
import { BottomSheetDefaultHeader } from "../../../components/BottomSheetDefaultHeader";
import { Divider } from "../../../components/Divider";
import { DriverDeviceUnbindDialog } from "./DriverDeviceUnbindDialog";
import SliderButton from "../../../components/SliderButton";
import { useEndWorkMutation, useStartWorkMutation } from "../states/services";
import toast from 'react-hot-toast';
import { IconStop } from '@consta/icons/IconStop';
import { IconCancel } from '@consta/icons/IconCancel';
import { DriverLogoutDialog } from "./DriverLogoutDialog";
import { Paths } from "../../../path";
import { useNavigate } from "react-router";
import { EquipmentState } from "../../../components/EquipmentStateLabel/types";
import { DriverState } from "../states";


const defaultHeaderSize = 96

export const DriverHome = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { data: scheduleData, error: scheduleError, isLoading: scheduleIsLoading, isFetching: scheduleisFetching, refetch: refetchSchedule } = useGetScheduleDataQuery()
    const { data: equipmentPositionData, refetch: refetchEquipmentPosition } = useGetEquipmentPositionQuery()
    const { refetch: refetchSlotDetails } = useGetSlotDetailsQuery(scheduleData?.slot.currentSlot?.activityId ?? "", { skip: !!!scheduleData?.slot.currentSlot?.activityId })
    const [startWork, { isLoading: startWorkIsLoading, isError: startWorkIsError, isSuccess: startWorkIsSuccess }] = useStartWorkMutation()
    const [endWork, { isError: endWorkIsError, isSuccess: endWorkIsSuccess }] = useEndWorkMutation()

    const regNumber = useSelector((state: RootState) => state.driver.regNumber)
    const selectedRequest = useSelector((state: RootState) => state.driver.selectedRequest)

    const [isProfileOpen, setIsProfileOpen] = useState(false);
    const [showUnbindErrorDialog, setShowUnbindErrorDialog] = useState(false);
    const [showLogoutDialog, setShowLogoutDialog] = useState(false);

    const sceduleListRef = useRef<SheetRef>();

    useEffect(() => {
        if (scheduleData && !scheduleError) {
            dispatch(updateCurrentSlot(scheduleData.slot.currentSlot))
            dispatch(updateEquipmentState(scheduleData.slot.resourceState))
            dispatch(updateCurrentEvent(scheduleData.schedule.event))
            dispatch(updateRegNumber(scheduleData.slot?.registrationPlateNumber))
            dispatch(updateCurrentRequest(scheduleData.schedule.requests?.find(element => element.id == scheduleData?.slot?.requestId)))
            dispatch(updateIssueRequest(scheduleData.schedule.issueRequest))
        }
    }, [scheduleData, scheduleError])

    useEffect(() => {
        if (scheduleError) {
            dispatch(updateCurrentSlot(null))
            dispatch(updateEquipmentState(undefined))
            dispatch(updateCurrentEvent(null))
            dispatch(updateRegNumber(undefined))
            dispatch(updateCurrentRequest(undefined))
            dispatch(updateIssueRequest(undefined))
        }
    }, [scheduleError])

    useEffect(() => {
        if (startWorkIsError) {
            toast.error('Произошла ошибка. Не удалось начать смену')
        }
        if (startWorkIsSuccess) {
            refetchSchedule()
        }
    }, [startWorkIsError, startWorkIsSuccess])

    useEffect(() => {
        if (endWorkIsError) {
            toast.error('Произошла ошибка. Не удалось завершить смену')
        }
        if (endWorkIsSuccess) {
            setIsProfileOpen(false)
            refetchSchedule()
        }
    }, [endWorkIsError, endWorkIsSuccess])

    useInterval(
        () => {
            refetchSchedule()
            refetchEquipmentPosition()
        },
        15 * 1000,
    )

    useEffect(() => {
        if (equipmentPositionData) {
            dispatch(updateSelectedEquipmentPosition(equipmentPositionData))
        }
    }, [equipmentPositionData])

    useEffect(() => {
        dispatch(updateEquipmentRoute(scheduleData?.slot.currentSlot?.geometry))
    }, [scheduleData])

    useEffect(() => {
        if (!showUnbindErrorDialog && scheduleError && 'status' in scheduleError) {
            if (scheduleError.status == 451 || scheduleError.status == 423) {
                setShowUnbindErrorDialog(true)
            }
        }
    }, [scheduleError])

    return (
        <>
            <SsmMap />

            <Button
                label="Меню"
                view="ghost"
                iconLeft={IconHamburger}
                onlyIcon
                onClick={() => setIsProfileOpen(true)}
                style={{
                    position: "absolute",
                    left: "10px",
                    top: "10px",
                    backgroundColor: "#ffffff",
                    touchAction: 'none',
                }}
            />

            <EquipmentStateAlertLabel />

            {scheduleData && !scheduleData.slot.shiftStarted && <div
                style={{
                    position: "absolute",
                    left: "10px",
                    right: "10px",
                    bottom: "30px",
                    touchAction: 'none',
                }}
            >
                <SliderButton
                    text='Начать смену'
                    color='#0078d2'
                    loading={(startWorkIsLoading || scheduleIsLoading) && scheduleData}
                    onSuccess={
                        () => startWork(undefined)
                    }
                />
            </div>
            }

            <ProfileSideBar
                isOpen={isProfileOpen}
                onClickOutside={() => setIsProfileOpen(false)}
                onLogout={scheduleData?.slot.shiftStarted ? () => {
                    setShowLogoutDialog(true)
                } : undefined}
                additional={
                    <Space direction="vertical" size="s">
                        <RegisterPlate regNumber={regNumber ?? 'г/н не найден'} />
                        <EquipmentStateLabel state={scheduleData?.slot.resourceState} />
                    </Space>
                }
                actions={[
                    scheduleData?.slot.shiftStarted && [
                        <Button
                            key='end_shift_btn'
                            size="m"
                            view="clear"
                            label="Завершить смену"
                            iconLeft={IconStop}
                            onClick={() => {
                                endWork(undefined)
                            }}
                        />,
                        <Divider key="div_1" />
                    ],

                    scheduleData?.slot.shiftStarted
                    && !scheduleData?.schedule.issueRequest
                    && scheduleData?.slot.resourceState != EquipmentState.STATE_OUT_OF_SERVICE
                    && [
                        <Button
                            key='offline_btn'
                            size="m"
                            view="clear"
                            label="Сойти с линии"
                            iconLeft={IconCancel}
                            onClick={() => navigate(Paths.driverOfflineRequest, { 'preventScrollReset': true })}
                        />,
                        <Divider key="div_2" />,
                    ]
                ]
                }
            />

            {scheduleData?.slot.shiftStarted && <Sheet
                style={{ zIndex: 5 }}
                ref={sceduleListRef}
                isOpen={true}
                onClose={() => { sceduleListRef.current?.snapTo(1) }}
                snapPoints={[window.innerHeight - 65, defaultHeaderSize]}
                detent="full-height"
                initialSnap={0}
            >
                <Sheet.Container>
                    <Sheet.Header>
                        <Theme preset={presetGpnDefault}>
                            <BottomSheetDefaultHeader />
                            <ScheduleHeader refetch={() => {
                                refetchSchedule();
                                if (scheduleData?.slot.currentSlot?.activityId) {
                                    refetchSlotDetails();
                                }
                            }}
                                isFetching={scheduleisFetching} />
                            <Divider />
                        </Theme>
                    </Sheet.Header>
                    <Sheet.Content>
                        <Sheet.Scroller draggableAt="both">
                            <Theme preset={presetGpnDefault}>
                                <div>
                                    {/* {scheduleData?.slot.resourceState && mapDriverState(scheduleData.slot.resourceState, scheduleData.schedule.issueRequest, scheduleData.slot.currentSlot?.type)} */}

                                    {!selectedRequest && !scheduleError && <DriverState />}

                                    {scheduleIsLoading && <ScheduleSkeleton />}

                                    {!scheduleIsLoading && scheduleError && <ErrorDataPlaceholder
                                        title="Произошла ошибка"
                                        description="Не удалось загрузить список заданий"
                                    />}

                                    {(!scheduleIsLoading && scheduleData && (!scheduleData.schedule.requests || scheduleData.schedule.requests.length === 0))
                                        && <EmptyDataPlaceholder
                                            title="Пусто"
                                            description="У вас нет назначенных заданий или все задания выполнены"
                                        />}

                                    {(!scheduleIsLoading && !scheduleError && scheduleData && scheduleData.schedule.requests && scheduleData.schedule.requests.length > 0)
                                        && <Schedule data={scheduleData} />}

                                </div>
                            </Theme>
                        </Sheet.Scroller>
                    </Sheet.Content>
                </Sheet.Container>
            </Sheet>}

            <DriverDeviceUnbindDialog
                isOpen={showUnbindErrorDialog}
                title={scheduleError && 'data' in scheduleError ? scheduleError.data as string : 'Ошибка привязки устройства'}
            />

            <DriverLogoutDialog
                isOpen={showLogoutDialog}
                onClickOutside={() => setShowLogoutDialog(false)}
            />
        </>
    )
}