import { Badge } from '@consta/uikit/Badge';
import { FC } from 'react';
import { DriverRequestState } from '../../../types';

interface Props {
    state: DriverRequestState
}

export const SlotStateLabel: FC<Props> = ({ state }) => {
    const labels: Record<DriverRequestState, string> = {
        [DriverRequestState.PLANNED]: "Запланирован",
        [DriverRequestState.DELAYED]: "Задержка",
        [DriverRequestState.IN_PROGRESS]: "В работе",
        [DriverRequestState.LOCKED_AUTO]: "Закреплен",
        [DriverRequestState.LOCKED_MANUAL]: "Закреплен",
        [DriverRequestState.COMPLETED]: "Завершен",
        [DriverRequestState.NOT_PERFORMED]: "Не подтвержено",
        [DriverRequestState.DEFICIT]: "Дефицит",
        [DriverRequestState.PART_COMPLETED]: "Завершен частично",
        [DriverRequestState.CONFIRMED]: "Подтвержено",
        [DriverRequestState.LOCKED]: "Закреплен",
    }

    const status: Record<DriverRequestState, "normal" | "error" | "success" | "warning" | "system" | undefined> = {
        [DriverRequestState.PLANNED]: "system",
        [DriverRequestState.DELAYED]: "warning",
        [DriverRequestState.IN_PROGRESS]: "normal",
        [DriverRequestState.CONFIRMED]: "normal",
        [DriverRequestState.LOCKED_AUTO]: "warning",
        [DriverRequestState.LOCKED_MANUAL]: "warning",
        [DriverRequestState.LOCKED]: "warning",
        [DriverRequestState.NOT_PERFORMED]: "system",
        [DriverRequestState.COMPLETED]: "success",
        [DriverRequestState.DEFICIT]: "error",
        [DriverRequestState.PART_COMPLETED]: "normal",
    }

    return <Badge status={status[state]} label={labels[state]} />
}