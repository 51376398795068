import { createApi } from '@reduxjs/toolkit/query/react'
import { GeometryPoint } from '../../components/Map/types'
import { baseQuery } from '../../base-service'
import { MasterCancelRequest, MasterRequests, MasterScheduleData } from './home/types'
import { ApiRoutes } from '../../utils/api-routes'

export const masterApi = createApi({
    reducerPath: 'ssmMasterApi',
    baseQuery: baseQuery,
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        getBaseCoordinates: builder.query<GeometryPoint, void>({
            query: () => ApiRoutes.getBaseCoordinates,
        }),
        getWorkPlan: builder.query<MasterScheduleData, void>({
            async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
                const requestsResponse = await fetchWithBQ(ApiRoutes.getWorkPlan)
                if (requestsResponse.error) {
                    return { error: requestsResponse.error }
                }

                const shiftResponse = await fetchWithBQ(ApiRoutes.getWorkShift)
                if (shiftResponse.error) {
                    return { error: shiftResponse.error }
                }

                

                const result = {
                    requests: (requestsResponse.data as MasterRequests).requests ?? [],
                    shift: shiftResponse.data,
                    newMessage: (requestsResponse.data as MasterRequests).newMessage
                } as MasterScheduleData

                return { data: result}
            }
        }),
        deleteRequest: builder.mutation<void, string>({
            query: (id) => ({
                url: ApiRoutes.deleteRequest(id),
                method: "DELETE",
            }),
        }),
        cancelRequest: builder.mutation<void, MasterCancelRequest>({
            query: (body) => ({
                url: ApiRoutes.cancelRequest,
                method: "POST",
                body: body
            }),
        }),
    }),
  })
  
  export const {
    useGetBaseCoordinatesQuery,
    useGetWorkPlanQuery,
    useDeleteRequestMutation,
    useCancelRequestMutation,
} = masterApi