import { DriverRequest, DriverSlot } from "../../../types";
import { Text } from '@consta/uikit/Text';
import { parseISO, format, intervalToDuration } from 'date-fns'
import { Space } from "../../../../../components/Space";
import { SlotStateLabel } from "./SlotStateLabel";
import { EquipmentRoute } from "../../../../../components/EquipmentRoute";

export function ScheduleItem({ item }: { item: DriverRequest }) {
    const duration = intervalToDuration({
        start: parseISO(item.beginDate),
        end: parseISO(item.endDate),
    })

    return <Space
        direction="vertical"
        size="2xs"
        style={{
            paddingLeft: 'var(--space-l)',
            paddingRight: 'var(--space-l)',
        }}
    >
        <Text view="primary" weight="bold"><SlotStateLabel state={item.state} /></Text>
        <Text view="primary" weight="semibold" size="l">{item.name}</Text>
        {/* <EquipmentRoute
            titleOf={item.titleOf}
            titleTo={item.titleTo}
        /> */}
        <Space size="xs">
            <Text view="secondary">{format(parseISO(item.beginDate), "HH:mm")} - {format(parseISO(item.endDate), "HH:mm")}</Text>

            {/* <Text view="secondary">{item.distance.toPrecision(2)} км</Text> */}

            <Text view="secondary">{duration.hours}ч {duration.minutes}м</Text>
        </Space>
    </Space>
}