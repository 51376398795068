import { useSelector } from "react-redux"
import { SkeletonText } from "@consta/uikit/Skeleton"
import { DriverErrorState } from "./DriverErrorState"
import { useEffect, useRef, useState } from "react"
import toast from 'react-hot-toast';
import { addMinutes, differenceInMinutes, isAfter, parseISO } from "date-fns"
import Sheet, { SheetRef } from 'react-modal-sheet';
import { presetGpnDefault, Theme } from "@consta/uikit/Theme"
import { TextField } from "@consta/uikit/TextField"
import { useInterval } from "react-use"
import { Text } from "@consta/uikit/Text"
import { RootState } from "../../../../store";
import { driverScheduleApi, useGetScheduleDataQuery, useGetSlotDetailsQuery } from "../../services";
import { useEndDowntimeMutation, useStartDowntimeMutation } from "../services";
import { EquipmentState } from "../../../../components/EquipmentStateLabel/types";
import { DriverEventType } from "../../types";
import SliderButton from "../../../../components/SliderButton";
import { SlotDetails } from "../SlotDetails";
import { Space } from "../../../../components/Space";
import { CalculateDurationFromMinutes } from "../../../../utils/dates";
import { BottomSheetTitleHeader } from "../../../../components/BottomSheetTitleHeader";
import { Divider } from "../../../../components/Divider";


export const DriverStateOperatingSlot = () => {
    const refBottomSheet = useRef<SheetRef>()

    const currentSlot = useSelector((state: RootState) => state.driver.currentSlot)
    const currentEvent = useSelector((state: RootState) => state.driver.currentEvent)
    const equipmentState = useSelector((state: RootState) => state.driver.equipmentState)

    const { data: details, isError: detailsIsError, isFetching: detailsIsFetching } = driverScheduleApi.endpoints.getSlotDetails.useQueryState(currentSlot?.activityId ?? "")
    const { data: schedule } = driverScheduleApi.endpoints.getScheduleData.useQueryState(undefined);
    const [startDowntime, { isLoading: startDowntimeIsLoading, isError: startDowntimeIsError, isSuccess: startDowntimeIsSuccess }] = useStartDowntimeMutation()
    const [endDowntime, { isLoading: endDowntimeIsLoading, isError: endDowntimeIsError, isSuccess: endDowntimeIsSuccess }] = useEndDowntimeMutation()
    const { refetch: refetchSchedule, isFetching } = useGetScheduleDataQuery(undefined, { skip: !startDowntimeIsSuccess && !endDowntimeIsSuccess })
    const { refetch: refetchDetails } = useGetSlotDetailsQuery(currentSlot?.activityId ?? "", { skip: !startDowntimeIsSuccess && !endDowntimeIsSuccess })

    const [isPauseDialogOpen, setIsPauseDialogOpen] = useState<boolean>(false);

    const [pauseComment, setPauseComment] = useState<string>('');
    const handleChange = ({ value }: { value: string | null }) => setPauseComment(value ?? '');

    const isStartDowntimeAvailable = (equipmentState == EquipmentState.STATE_WAITING || equipmentState == EquipmentState.STATE_ARRIVAL_UNCONFIRMED) && schedule?.slot.registeredAt && isAfter(new Date(), addMinutes(parseISO(schedule?.slot.registeredAt), 1)) && isAfter(new Date(), parseISO(schedule?.slot.currentSlot.beginDate)) && currentEvent?.type != DriverEventType.RESOURCE_DOWNTIME
    const isFinishDowntimeAvailable = (equipmentState == EquipmentState.STATE_WAITING || equipmentState == EquipmentState.STATE_ARRIVAL_UNCONFIRMED) && currentEvent?.type == DriverEventType.RESOURCE_DOWNTIME
    const eventDate = currentEvent?.beginDate ? parseISO(currentEvent.beginDate) : undefined
    const [minutesFromDowntime, setMinutesFromDowntime] = useState<number>(0);

    useEffect(() => {
        if (startDowntimeIsError || endDowntimeIsError) {
            toast.error('Произошла ошибка. Не удалось выполнить запрос')
        }
    }, [startDowntimeIsError, endDowntimeIsError])

    useEffect(() => {
        if (startDowntimeIsSuccess || endDowntimeIsSuccess) {
            setIsPauseDialogOpen(false)
            refetchSchedule()
            refetchDetails()
        }
        if (startDowntimeIsSuccess) {
            setMinutesFromDowntime(0)
        }
    }, [startDowntimeIsSuccess, endDowntimeIsSuccess])

    useInterval(
        () => {
            if (eventDate && currentEvent?.type == DriverEventType.RESOURCE_DOWNTIME) {
                setMinutesFromDowntime(differenceInMinutes(new Date(), eventDate))
            }
        },
        1 * 1000,
    )

    return <>
        {detailsIsFetching && <div
            style={{
                paddingTop: 10,
                paddingLeft: 'var(--space-l)',
                paddingRight: 'var(--space-l)',
            }} >
            <SkeletonText className="text" rows={8} fontSize="l" lineHeight="l" />
        </div>}

        {detailsIsError && <DriverErrorState />}

        {!detailsIsFetching && details && <div style={{ paddingTop: 10 }}>
            <Space
                direction="vertical"
                size="l"
                style={{
                    paddingLeft: 'var(--space-l)',
                    paddingRight: 'var(--space-l)',
                }}
            >
                <SlotDetails details={details} />

                {isStartDowntimeAvailable
                    ? <SliderButton
                        text='Начать ожидание'
                        color='#002033'
                        onSuccess={() => { setIsPauseDialogOpen(true) }}
                    /> : <></>}

                {isFinishDowntimeAvailable ? <Text view="caution">Время ожидания {CalculateDurationFromMinutes({ minutes: minutesFromDowntime! }).drurationHours}ч : {CalculateDurationFromMinutes({ minutes: minutesFromDowntime! }).drurationMinutes}мин</Text> : <></>}

                {isFinishDowntimeAvailable
                    ? <SliderButton
                        loading={endDowntimeIsLoading || isFetching}
                        text='Завершить ожидание'
                        color='#002033'
                        onSuccess={() => { endDowntime(currentEvent!.id) }}
                    /> : <></>}

                <div style={{ height: 1 }} />
            </Space>
        </div>}

        <Sheet
            ref={refBottomSheet}
            isOpen={isPauseDialogOpen}
            onClose={() => {
                setIsPauseDialogOpen(false)
                setPauseComment('')
            }}
            snapPoints={[260]}
            initialSnap={0}
        >
            <Sheet.Container>
                <Sheet.Header>
                    <Theme preset={presetGpnDefault}>
                        <BottomSheetTitleHeader
                            title='Задержка выполнения'
                            onClose={() => {
                                setIsPauseDialogOpen(false)
                                setPauseComment('')
                            }}
                        />
                        <Divider />
                    </Theme>
                </Sheet.Header>
                <Sheet.Content disableDrag={true}>
                    <Theme preset={presetGpnDefault}>
                        <Space
                            direction="vertical"
                            style={{
                                paddingLeft: 'var(--space-s)',
                                paddingRight: 'var(--space-s)',
                                paddingTop: 'var(--space-s)',
                            }}
                        >
                            <TextField
                                placeholder="Введите комментарий"
                                onChange={handleChange}
                                value={pauseComment}
                                type="textarea"
                                rows={3}
                            />
                            <SliderButton
                                loading={startDowntimeIsLoading || isFetching}
                                text='Отправить'
                                color='#0078d2'
                                onSuccess={() => {
                                    startDowntime({ resourceId: currentSlot!.resourceId, comment: pauseComment })
                                    setPauseComment('')
                                }}
                            />
                        </Space>
                    </Theme>
                </Sheet.Content>
            </Sheet.Container>
            <Sheet.Backdrop />
        </Sheet>
    </>
}