export function checkConnection() : boolean {
    if (navigator.onLine) {
        isReachable(getServerUrl()).then(function (online) {
            if (online) {
                console.log('online')
                return true
            } else {
                console.log('no connectivity')
                return false
            }
        });
    } else {
        return false
    }
    return true
}

function isReachable(url: string) {
    return fetch(url, { method: 'HEAD', mode: 'no-cors' })
        .then(function (resp) {
            return resp && (resp.ok || resp.type === 'opaque')
        })
        .catch(function (err) {
            console.warn('[conn test failure]:', err)
        })
}

function getServerUrl() {
    return 'https://smartservice-qa.apps.okd3.bright-group.ru' //window.location.origin
}