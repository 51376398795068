import { FC, ReactElement, useState } from 'react';
import { createKeycloak } from './Keycloak';
import { useMount } from 'react-use'
import { fetchXsrfToken, useGetUserQuery } from './user/services';
import { StorageKeys } from './utils/storage-keys';
import { useGetSystemSettingsQuery } from './mdm/services';
import { initializeNotification } from './notifications';
import { createOfflineMode } from './offline-mode/core';

interface Props { children: ReactElement }

export const App: FC<Props> = ({ children }) => {
  const [initialized, setInitialized] = useState(false)

  useMount(async () => {
    createOfflineMode()
    await createKeycloak()
    await fetchXsrfToken()
    sessionStorage.removeItem(StorageKeys.keycloakConfig)
    initializeNotification()
    setInitialized(() => true)
  })

  useGetUserQuery(undefined, { skip: !initialized })
  useGetSystemSettingsQuery(undefined, { skip: !initialized })

  return children
}
