import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DriverEvent, DriverRequest, DriverSlot, IssueRequest } from "./types";
import { EquipmentState } from "../../components/EquipmentStateLabel/types";
import { StorageKeys } from "../../utils/storage-keys";

export interface DriverState {
    currentSlot: DriverSlot | null | undefined
    currentRequest: DriverRequest | null | undefined
    selectedRequest: DriverRequest | null | undefined
    currentEvent: DriverEvent | null | undefined
    equipmentState: EquipmentState | undefined
    issueRequest: IssueRequest | undefined
    regNumber: string | undefined
    hasApprovedDeviceId: boolean
    forcedUnbindDialog: boolean
}

const initialState: DriverState = {
    currentSlot: null,
    currentRequest: null,
    selectedRequest: null,
    issueRequest: undefined,
    currentEvent: null,
    equipmentState: undefined,
    regNumber: undefined,
    hasApprovedDeviceId: localStorage.getItem(StorageKeys.deviceId) != null,
    forcedUnbindDialog: false,
}

export const driverSlice = createSlice({
    name: 'driver',
    initialState,
    reducers: {
        updateCurrentSlot: (state, action: PayloadAction<DriverSlot | null>) => {
            state.currentSlot = action.payload
        },
        updateCurrentRequest: (state, action: PayloadAction<DriverRequest | undefined>) => {
            state.currentRequest = action.payload
        },
        updateSelectedRequest: (state, action: PayloadAction<DriverRequest | undefined>) => {
            state.selectedRequest = action.payload
        },
        updateIssueRequest: (state, action: PayloadAction<IssueRequest | undefined>) => {
            state.issueRequest = action.payload
        },
        updateCurrentEvent: (state, action: PayloadAction<DriverEvent | null>) => {
            state.currentEvent = action.payload
        },
        updateEquipmentState: (state, action: PayloadAction<EquipmentState | undefined>) => {
            state.equipmentState = action.payload
        },
        updateRegNumber: (state, action: PayloadAction<string | undefined>) => {
            state.regNumber = action.payload
        },
        updateHasApprovedDeviceId: (state, action: PayloadAction<boolean>) => {
            state.hasApprovedDeviceId = action.payload
        },
        updateForcedUnbindDialog: (state, action: PayloadAction<boolean>) => {
            state.forcedUnbindDialog = action.payload
        },
    },
})

export const {
    updateCurrentSlot,
    updateCurrentRequest,
    updateSelectedRequest,
    updateCurrentEvent,
    updateEquipmentState,
    updateIssueRequest,
    updateRegNumber,
    updateHasApprovedDeviceId,
    updateForcedUnbindDialog,
} = driverSlice.actions

export default driverSlice.reducer