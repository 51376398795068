import OfflineMode from "./OfflineMode";

let offlineMode: OfflineMode | null = null

export const createOfflineMode = async () => {
    offlineMode = new OfflineMode()
    offlineMode.init()
}

export { offlineMode }
