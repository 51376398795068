import { createApi } from '@reduxjs/toolkit/query/react'
import { DriverCurrentSlot, DriverSchedule, DriverScheduleData } from './types'
import { EquipmentPosition } from '../../components/Map/types'
import { baseQuery } from '../../base-service'
import { DriverSlotDetails } from './states/SlotDetails/types'
import { checkConnection } from '../../utils/connection'
import { ApiRoutes } from '../../utils/api-routes'

export const driverScheduleApi = createApi({
    reducerPath: 'driverScheduleApi',
    baseQuery: baseQuery,
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        // getSchedule: builder.query<DriverSchedule, void>({
        //     query: () => ApiRoutes.getJobScheduleRequest,
        // }),
        getCurrentSlot: builder.query<DriverCurrentSlot, void>({
            async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
                const response = await fetchWithBQ(ApiRoutes.getCurrentSlot)
                if (response.error) {
                    if (response.meta?.response?.status == 409
                        || response.meta?.response?.status == 451
                        || response.meta?.response?.status == 423) {
                        return {
                            error: {
                                status: response.meta?.response?.status,
                                data: response.error.data
                            }
                        }
                    }
                    return { error: response.error }
                }
                return { data: response.data as DriverCurrentSlot }
            }
        }),
        getScheduleData: builder.query<DriverScheduleData, void>({
            async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
                const scheduleResponse = await fetchWithBQ(ApiRoutes.getJobScheduleRequest)
                if (scheduleResponse.error) {
                    if (scheduleResponse.meta?.response?.status == 409
                        || scheduleResponse.meta?.response?.status == 451
                        || scheduleResponse.meta?.response?.status == 423) {
                        return {
                            error: {
                                status: scheduleResponse.meta?.response?.status,
                                data: scheduleResponse.error.data
                            }
                        }
                    }
                    return { error: scheduleResponse.error }
                }

                const slotResponse = await fetchWithBQ(ApiRoutes.getCurrentSlot)
                if (slotResponse.error) {
                    if (slotResponse.meta?.response?.status == 409
                        || slotResponse.meta?.response?.status == 451
                        || slotResponse.meta?.response?.status == 423) {
                        return {
                            error: {
                                status: slotResponse.meta?.response?.status,
                                data: slotResponse.error.data
                            }
                        }
                    }
                    return { error: slotResponse.error }
                }

                const shiftResponse = await fetchWithBQ(ApiRoutes.getWorkShift)
                if (shiftResponse.error) {
                    return { error: shiftResponse.error }
                }

                const result = {
                    schedule: scheduleResponse.data,
                    slot: slotResponse.data,
                    shift: shiftResponse.data
                } as DriverScheduleData

                return { data: result }
            }
        }),
        getEquipmentPosition: builder.query<EquipmentPosition, void>({
            query: () => ApiRoutes.getEquipmentPosition,
        }),
        getSlotDetails: builder.query<DriverSlotDetails, string>({
            query: (id) => ApiRoutes.getSlotDetails(id),
        }),
    }),
})

export const {
    // useGetScheduleQuery,
    useGetCurrentSlotQuery,
    useGetEquipmentPositionQuery,
    useGetScheduleDataQuery,
    useGetSlotDetailsQuery,
} = driverScheduleApi