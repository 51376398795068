import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { Space } from "../../../components/Space";
import { Text } from '@consta/uikit/Text';
import { Divider } from "../../../components/Divider";
import { DriverRequestTimeline } from "./DriverRequestTimeline";
import { mapDriverState } from "./mapper";


export const DriverState = () => {
    const currentRequest = useSelector((state: RootState) => state.driver.currentRequest)
    const currentSlot = useSelector((state: RootState) => state.driver.currentSlot)
    const equipmentState = useSelector((state: RootState) => state.driver.equipmentState)
    const issueRequest = useSelector((state: RootState) => state.driver.issueRequest)


    return currentRequest ? <div>
        <Space
            size="s"
            direction="vertical"
            style={{ padding: 'var(--space-l)' }}
        >
            <Text view="primary" weight="medium" size="m">{currentRequest.name}</Text>
            <Text view="secondary" weight="medium" size="m">{currentRequest.phoneNumber}</Text>

            <Divider />

            <DriverRequestTimeline request={currentRequest} />
        </Space>

        {mapDriverState(equipmentState, issueRequest, currentSlot?.type)}
    </div > : <></>
}